@import url('https://fonts.googleapis.com/css2?family=Boldonse&family=Karla:ital,wght@0,200..800;1,200..800&display=swap');

:root {
    --darkblue: #000069;
    --lightblue: #8EA9FF;
    --offwhite: #F3F4F2;
    --gray: #575757;

    font-size: 16px;

    @media (min-width: 2000px) {
        font-size: 24px;
    }
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

body {
    font-family: 'Karla', sans-serif;
    // font-size: 16px;
    color: var(--darkblue);
    min-height: 100vh;
}

.homepage25 {
    background-color: var(--darkblue);
    min-height: 100vh;
    padding: 0 5%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 100vh;

    @media (max-width:768px) {
        padding: 0 3%;
    }
}

h1 {
    display: inline-block;
    font-family: 'Boldonse', sans-serif;
    color:var(--lightblue);
    margin-top: 4%;
    margin-bottom: 0px;
    line-height: 1.3;
    // font-size: 14vw;
    font-size: clamp(20px, 14vw, 450px);
    text-indent: -0.5rem;
    // border: 1px solid red;
    cursor: default;

    @media (max-width: 768px) {
        font-size: clamp(35px, 18vw, 80px);
    }

    &.h1-small {
        font-size: 12vw;
        text-indent: -0.3rem;
        margin-top:5%;
        position: absolute;
        z-index: -1;

        @media (max-width:768px) {
            position: relative;
            transform: translate(0px, -8rem);
            font-size: clamp(70px, 18vw, 80px);
        }
    }

    span {
        transition: all 0.2s ease;
        &:hover {
            color: white;
            
        }

    }

}

.sticky {
    position: sticky;
    top: 0;
    // padding-bottom: 400px;
    max-height: 40vh;
    z-index: 5;
    isolation: isolate;
    // border: 1px solid orange;

    @media (max-width:768px) {
        min-height:0vh;
        margin-top: -58vh;
    }
}

.sticky-scroll {
    flex-grow: 1;
    flex-direction: column;
    height: 60vh;
    // border:1px solid purple;

    @media (max-width:768px) {
        height:106vh;
    }
}

.top1 {
    align-items: flex-end;
    flex-direction: row;

    &__home {
        text-decoration: none;
        color: white;
        transition: all 0.1s;
        cursor: pointer;

        &:hover {
            // font-style: normal;
            letter-spacing: 4px;
            border-bottom: 2px solid var(--lightblue);
        }
    }

    &__hello {
        color: white;
        &:hover {
            color: var(--lightblue);
        }
    }
}

.top2 {
    align-items: flex-end;
    flex-direction: row;
    
    @media (max-width:768px) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
    }
}


.header {
    &__container {
        display: block;
        width: 97%;
        margin: 0 auto;
        max-width: 100vw;
        padding-top: 6%;
        min-height: 40px;
        // border: 1px solid green;

        @media (max-width:768px) {
            padding-left:3%;
            padding-right: 3%;
            height: 85vh;
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        width: 100%;

        span.headerspan {
            font-style: italic;
            font-weight: bold;
            font-size: 1.5rem;
            // color: white;

            @media (max-width:768px) {
                font-size: 1.2rem;
                padding-bottom: 10px;
            }
        }
    }

    &__contact {
        color: var(--lightblue);
        text-align: right;
        font-size: 1.2rem;
        font-style: italic;
        font-weight: 600;
        padding-bottom: 3%;
        // border: 1px solid orange;
        
        @media (max-width:768px) {
            margin-left: auto;
            font-size: 1.1rem;
            line-height: 0.9;
        }

        @media (min-width:2000px) {
            font-size: 1.5rem;
        }
        

        a {
            color: var(--lightblue);
            text-decoration: none;
            transition: all 0.2s ease;

            &:hover {
                font-style: normal;
                font-weight: 900;
                color: white;
            }
        }
    }

    &__intro {
        background-color: var(--offwhite);
        border-style: solid solid none solid;
        border-color: var(--darkblue);
        border-width: 3px;
        z-index: 10;
        padding: 10px 22px;
        position: relative;
        // margin-top: calc(100% - 50vh); 

        @media (max-width:768px) {
            // padding: 10px 10px;
        }

        div {
            max-width: 710px;
            font-size: 1.8rem;
            line-height: 1.5;
            // padding-bottom: 40px;

            @media (max-width:768px) {
                font-size: 1.2rem;
            }

            @media (min-width:2000px) {
                font-size: 1.9rem;
                min-width: 50%;
                padding: 10px 30px;
            }
        }

        p {
            padding-bottom: 5%;
        }
    }

    &__hr {
        border-top: 3px solid var(--darkblue);

        @media (max-width:768px) {
            border-top: 2px solid var(--darkblue);
        }
    }
}

.nav-container {
    padding-bottom: 0px;
    // border: 1px solid pink;

    @media (max-width:768px) {
        display: flex;
        justify-content: space-between;
        transform: translate(-8px,0px);
    }
}


.nav {
    font-family: 'Boldonse', sans-serif;
    display: inline-block;
    min-width: 240px;
    text-decoration: none;
    transition: all 0.2s ease;
    // border: 1px solid yellow;

    &:before {
        content:'➼';
        opacity: 0;
        transition: all 0.2s ease; 
        position: relative;
        top: -6px;
        right: 4px;
    }
    

    @media (max-width:768px) {
        min-width: 100px;
        font-size: 0.9rem;

        &:before {
            top: -2px;
            right:2px;
        }
    }

    @media (min-width:2000px) {
        min-width: 400px;
        font-size: 1.5rem;
        transform: translate(0, 15px);
    }

    &:link, &:visited  {
        color: var(--darkblue);
    }

    &:hover {
        color: var(--lightblue);
    }

    &.active {
        color: var(--lightblue);

        &:before {
            opacity: 1;
        }
    }
}


.project {
    &__container {
        display: flex;
        flex-direction: column;
        // gap: 20px;
    }

    &__card {
        position: relative;
        // display: flex;
        // flex: 2;
        display: grid;
        grid-template-columns: 1.6fr 1fr;
        min-height: 400px;
        width: 100%;
        background-color: var(--offwhite);
        border-top: none;
        border-right: 3px solid var(--darkblue);
        border-bottom: 0.5px dotted var(--lightblue);
        border-left: 3px solid var(--darkblue); 
        z-index: 11;
        padding: 2% 3%;
        transition: all 0.3s ease;
    
        &:hover {
            border-radius: 45px;

            .project__title a {
                border-bottom: 2px solid var(--lightblue);
            }
        }

        @media (max-width:1025px) {
            grid-template-columns: 1fr;
            padding-top: 30px;
            padding-bottom: 15px;;

            &:hover {
                border-radius: 25px;
            }
        }

        @media (min-width:2000px) {
            padding: 3% 5%;

            &:hover {
                border-radius: 85px;

                .project__title a {
                    border-bottom: 4px solid var(--lightblue);
                }
            }
        }

    }

    &__image-container {
        // flex: 2;
        position: relative;
        width: 100%;
        aspect-ratio: 16 / 9;
        // height: 500px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid var(--darkblue);

        // @media (max-width:768px) {
        //     width:100%;
        // }
    }

    &__details {
        padding: 0px 20px 20px 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        // border: 1px solid orange;

        @media (max-width:768px) {
            padding: 0px 5px 5px 5px;
        }

        p {
            font-size: 0.9rem;
            // line-height: 1.7;

            @media (max-width:768px) {
                font-size: 0.8rem;
            }
          }
    }

    &__title {
        font-weight: 800;
        font-style: italic;
        font-size: 1.4rem;
        line-height: 1.5;
        // padding-bottom: 8px;
        
        a {
            text-decoration: none;
            color: var(--darkblue);
            border-bottom: 1px dotted;

            &:hover {
                .out-icon {
                    transform: translate(0px, 1px);
                    transition: all 0.1s ease-in;
                    // filter: invert(7%) sepia(65%) saturate(7459%) hue-rotate(243deg) brightness(78%) contrast(122%);
                    filter:invert(57%) sepia(0%) saturate(216%) hue-rotate(210deg) brightness(106%) contrast(125%);
                    }
                }
        }
        
        @media (max-width:768px) {
            font-size: 1.2rem;;
        }

        @media (min-width:2000px) {
            font-size: 1.6rem;
        }
    }

    &__role {
        font-weight: bold;
        margin-right: 25px;

        @media (max-width:768px) {
            margin-right: 15px;
        }
    }

    &__description {
        white-space: pre-line;
        margin-top: auto;
    }

    &__award {
        font-size: 0.85rem;
        font-style: italic;
        color: var(--gray);
        // text-decoration: none;
        line-height: 1.4;

        &:hover {
            font-weight: bold;
        }
    }

    

}

.image-scroll {
    display: flex;
    gap: 8px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    width: 100%;
    height: 100%;
    position: relative;

    &__item {
        flex-shrink: 0;
        // width: 97%;
        // height: 99%;
        // width: 98%;
        // height: 99%;
        // width: auto;
        width: auto;
        height: 99%;
        scroll-snap-align: start;

        @media (max-width:768px) {
            width: 100%;
        }

        img, video {
            width: 100%;
            // height: 99%;
            object-fit: cover;
        }
    }
}

.out-icon {
    filter: invert(19%) sepia(97%) saturate(4166%) hue-rotate(241deg) brightness(97%) contrast(111%);
    transform: translate(-4px, 6px);
    margin-left: 2px;
    width:20px;
    height:20px;

    @media (min-width:2000px) {
        width: 30px;
        height: 30px;
    }
  }

.right-arrow {
    filter: invert(100%) sepia(0%) saturate(5442%) hue-rotate(90deg) brightness(99%) contrast(110%);
    transform: translate(2px, 0px);
    width: 15px;
    height: 15px;
    transition: transform 0.4s ease;

    @media (min-width:2000px) {
        width: 19px;
        height: 19px;
    }
}

.next-btn {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%); 
    width: 25px;
    height: 50px; 
    background-color: rgba(0, 0, 105, 0.9);
    // border: none;
    border-top-style: solid;
    border-right-style: none;
    border-bottom-style: solid;
    border-left-style: solid;
    border-color: var(--lightblue);
    border-width: 1px;
    border-top-left-radius: 50px; 
    border-bottom-left-radius: 50px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    transition: background-color 0.3s ease;
    

    &:hover {
        background-color: var(--lightblue);
        border-color: var(--darkblue);
        // border-color: white;

        .right-arrow {
            filter: invert(6%) sepia(89%) saturate(7496%) hue-rotate(226deg) brightness(77%) contrast(139%);
            transform: translate(4px, 0px);
        }
    }

    @media (min-width:2000px) {
        width:25px;
        height:55px;
    }
}




.gallery-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap:80px;
    max-width: 90vw;

    @media (max-width:768px) {
        grid-template-columns: 1fr;
    }

    &__item {
        // border: 1px solid red;

        
        img, video {
            width:100%;
        }
    }

    &__caption {
        font-size: 0.85rem;
        font-style: italic;
        display: block;
        text-align: center;
        color:#87878c;

        a {
            color:#87878c;

            &:hover {
                color: var(--darkblue);
            }
        }
    }
    
}

.detail-padding {
    height: 50px;
}


.detail-page {
    z-index: 10;
    position: relative;
    background-color: var(--offwhite);
    color: black;
    // background-color: #1a1b1f;
    padding: 0 3% 4% 3%;
    border-style: none solid solid solid;
    border-color: var(--darkblue);
    border-width: 3px;
    min-height: 100vh;

    img {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 3%;
    }

    // p {
    //     line-height: 1.7;
    // }
}

.page {
    &__title {
        font-size: 2.1rem;
        font-style: italic;

        @media (max-width:768px) {
            font-size: 1.4rem;
        }
    }

    &__text {
        line-height: 1.7;
        max-width: 700px;

        @media (max-width:768px) {
            font-size: 0.95rem;    
        }

        @media (min-width:2000px) {
            max-width: 45%;
        }

        a {
            font-style: italic;
            font-weight: bold;
            color: var(--darkblue);
            text-decoration: none;
            border-bottom: 0.5px dotted var(--darkblue);
        }
    }
     
    &__intro {
        font-size:1.35rem;
        line-height: 1.6;
        padding-bottom: 10%;

        @media (max-width:768px) {
            font-size: 1.15rem;
            line-height: 1.4;
        }
    }

    &__grid {
        display: grid;
        grid-template-columns: minmax(300px, 710px) 1fr;
        column-gap: 50px;
        align-items: center;
        
        // div {
        //     border: 1px solid green;
        // }
        @media (max-width:768px) {
            grid-template-columns: 1fr;
            align-items: start;
        }

        @media (min-width:2000px) {
            grid-template-columns: 980px 1fr;
        }
    }

    &__challenges {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap:0px 30px;
        line-height: 1.4;
        
        @media (max-width:768px) {
            margin: 0 auto;
        }

        &-body {
            font-size: 0.9rem;

            @media (max-width:768px) {
                font-size: 0.8rem;
            }
        }
    }

    &__caption {
        color: var(--gray);
        display: block;
        text-align: center;

        @media (max-width:768px) {
            font-size: 0.9rem;
        }
    }

    &__fullimg {
        width:100vw;
    }
}

footer {
    color: var(--lightblue);
    padding: 5% 1% 2% 1%;
    // padding:2% 6%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);;
    font-size: 0.85rem;
    line-height: 1.5;
  
    div {
      &:nth-child(1) {
        font-weight: bold;
      }
  
      &:nth-child(2) {
      text-align: center;
      }
  
      &:nth-child(3) {
        text-align: right;
      }
    }

    a {
        color: var(--lightblue);
        text-decoration: none;
        border-bottom: 0.5px dotted var(--lightblue);

        &:hover {
            border-bottom: 0.5px solid var(--lightblue);
        }
    }
  
    @media (max-width:768px) {
      display: flex;
      flex-direction: column;
      font-size: 0.65rem;
      padding: 8% 3%;
  
      div {
        &:nth-child(1) {
          order: 1;
        }
  
        &:nth-child(2) {
          order: 2;
          text-align: left;
          margin-bottom: 10px;
        }
  
        &:nth-child(3) {
          order: 3;
          text-align: left;
        }
      }
    }
  }
  